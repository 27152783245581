import axios from "axios";
import React,{ useState, useEffect} from 'react';
import DataTable,{ ExpanderComponentProps } from 'react-data-table-component';
import logoLeft from "../images/green_prix_logo.png";
import logoRight from "../images/BarcomLogo_Tag_2color.png";
import tvaLogo from "../images/TVALogoTextRGBCharcoal.jpg";
import sponsorLogo from "../images/EPB-logo.jpg";
import vwLogo from "../images/Volkswagen Logo.png";
import loadingImage from "../images/loading.gif";
import "../css/customStyle.css";
import moment from 'moment';

export default function AxiosData(){
  //AXIOS - LOCATION

	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});
	// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
	let value = params.location; // "CHATTANOOGA"

	if(value == null){
		value = "CHATTANOOGA";
	}

  	const [headerLoading, setHeaderLoading] = useState(false);
	const [raceLocation,setRaceLocation] = useState([]);

	const queryLocation = ' https://greenprix.barcominc.com:8004/api/v1/GetLocation';

	useEffect(()=>{
		setHeaderLoading(true);
		axios.get(queryLocation)
		.then(res=> {
			setRaceLocation(res.data.recordset);
			setHeaderLoading(false);
			getRaceDate();
    	})
	},[])
	
	const [detail,setDetail] = useState({});
		
	function handle(e){
		setDetail({location: e.target.value});
	};	
	const [raceDate,setRaceDate] = useState([]);
  	const [raceData,setRaceData] = useState({
		date:""
  	});
	
  // AXIOS - RACE DATE
	const query = ' https://greenprix.barcominc.com:8004/api/v1/GetRaceDate?location='+value;
	
	const getRaceDate=()=>{
		axios.get(query)
		.then(res=> {
     				 setRaceDate(res.data.recordset);
					})
	};
	
  //AXIOS - RACE TYPE
  
	const [raceType,setRaceType] = useState({
    	type:""
	});
	const [raceTypes,setRaceTypes] = useState([]);
	
	const queryRaceType = ' https://greenprix.barcominc.com:8004/api/v1/GetRaceType?location='+value+'&date='+raceData.date;
	
	const getRaceType=()=>{
		axios.get(queryRaceType)
		.then(res=> {
      if(res.data)
      {
        setRaceTypes(res.data.recordset);
      }
    })
	};

	function handleRaceType(event){
		setRaceType({type: event.target.value});
	};
  
	function handleRaceDate(event){
		setRaceData({date:moment(event.target.value).format('YYYY-MM-DD')});
	};

	
	//AXIOS - All
	const [summary,setSummary] = useState({});
	const [team,setTeam] = useState([]);
	const teamQuery = ' https://greenprix.barcominc.com:8004/api/v1/GetRaceSummary?location='+value+'&date='+raceData.date+'&raceType='+ encodeURIComponent(raceType.type);
	
	const [dataLoading, setDataLoading] = useState(false);
	const getTeam=()=>{
		setDataLoading(true);
		axios.get(teamQuery)
		.then(res=> {
			setTeam(res.data.recordset);
			setDataLoading(false);
		})
	};

	useEffect(() => {
		const timer = setTimeout(() => getTeam(), 500);
		return () => clearTimeout(timer);
	  }, [raceType]);

	// Refresh time for table fatch
	const [refreshTime, setRefreshTime] = useState(15000);

	const [checked, setChecked] = useState(true);

	const [btnDisplay, setBtnDisplay] = useState("none");
 	
  	window.stopinterval = false;
  
	function handleRefresh(event){
		if(event.target.checked){
			setBtnDisplay("none");
			window.stopinterval = false;
			window.interval = setInterval( () =>{
				getRaceDate();
				getRaceType();
				getTeam()
			}, refreshTime);
		} else{
				setBtnDisplay("block");
				clearInterval(window.interval);
				window.stopinterval = true;
		}
	}

	function handleRefreshBtn(e){
		setTeam([]);
		const ref = setTimeout(() => 
			getRaceDate(),
			getRaceType(),
			getTeam(),500);
		return () => clearTimeout(ref);
	}

	useEffect(()=>{
    if(!window.stopinterval)
    {
      getRaceDate();
      getRaceType();
      
      window.interval = setInterval( () =>{
         if(!window.stopinterval)
          {
              getRaceDate();
              getRaceType();
              getTeam()
          }
      }, refreshTime)
    }
		return()=>clearInterval(window.interval);
	},[raceType]);
    
	useEffect(()=>{
		getRaceType();
	},[raceData]);

	// Data provides for dropdown table
	const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
  
	const detailQuery = ' https://greenprix.barcominc.com:8004/api/v1/GetRaceDetail?location='+value+'&date='+raceData.date+'&raceType='+encodeURIComponent(raceType.type)+'&classId='+encodeURIComponent(data.class)+'&carNumber='+encodeURIComponent(data.carnr);
		
	const [driverDetail,setDriverDetail]=useState([]);
	const [loading , setLoading] = useState(false);

	useEffect(()=>{
		setLoading(true);
		axios.get(detailQuery)
		.then(res=> {
			setDriverDetail(res.data);
			setLoading(false);
		})
	},[setDriverDetail])
		return <>
			{ loading? (
				<img src={loadingImage} alt="Loading..." className="detailsImg"/>
			):(
				<>
					{Object.entries(driverDetail).map(([key, subject], i) => (
						<p key={i}>{subject.Detail}</p>
					))}
				</>
			)}
			
		</>
		};

	//Sort
	const caseInsensitiveSort = (rowA, rowB) => {
    
 
		const a = rowA[Object.keys(rowA)[0]];
		const b = rowB[Object.keys(rowB)[0]];

		if (a > b) {
			return 1;
		}

		if (b > a) {
			return -1;
		}

		return 0;
	};

	//DATA-TABLE - Content
	const columns = [
		{
			name: 'Class',
			selector: row => row.classId,
			sortable: true,
			sortFunction: caseInsensitiveSort,
		},		
		{
			name: 'Team',
			selector: row => row.teamName,
			sortable: true,
		},				
		{
			name: 'Car Number',
			selector: row => row.carNumber,
			sortable: true,
		},		
		{
			name: 'Total Laps',
			selector: row => row.totalLaps,
			sortable: true,
		},
		{
			name: 'Fastest Lap',
			selector: row => row.fastestLaps,
			sortable: true,
		},	
		{
			name: 'Time',
			selector: row => row.time,
			sortable: true,
		},					
	];
	
	const data=[];
	
	if(typeof window.sortedcolumn == 'undefined'){
		window.sortedcolumn=4;
		window.sortDirection = false;
		window.expandedrow = "{}";
	}
	const handleSort = (column, sortDirection) => {
			// simulate server sort
			window.sortedcolumn = column.id;
        if(sortDirection == "asc")
        {
        window.sorteddirection = true;
        }else{
        window.sortDirection = false;
        }	
		};
	const handleRowExpand = (expanded, row) => {
			// simulate server sort
			if(expanded){
          window.expandedrow = JSON.stringify(row);
      }else{
        window.expandedrow = "{}";
      }
		};
	
	const handleExpansion = (row) => {
		let expanded_data = JSON.parse(window.expandedrow);
		if(expanded_data != {}){
		if((row.teamName === expanded_data.teamName) && (row.carnr === expanded_data.carnr)){
			return true;
		}else{
			return false;
		}
		}else{
		return false;
		}
	};
  
  let finalData= []
	if(team === undefined){
		let data=[];
	}else{
		let datafilter = team.map((i)=>{
			return i;
		})
    let header_loaded = 0;
    team.map(entry =>{
      if(header_loaded == 0){
          let firstcolumn = 0;
          Object.keys(entry).forEach(element => {
            if(firstcolumn == 0){
              finalData.push({
                name: element,
                selector: row => row[element.toLowerCase().replace(/ /g,"").replace(/#/g,"nr")],
                sortable: true,
                sortFunction: caseInsensitiveSort,
              });
              firstcolumn=1;
            }else{
              finalData.push({
                name: element,
                selector: row=> row[element.toLowerCase().replace(/ /g,"").replace(/#/g,"nr")],
                sortable: true,
              });
            }
          });
          header_loaded = 1;
      }
      let row = {};
      Object.keys(entry).forEach(element => {
          row[element.toLowerCase().replace(/ /g,"").replace(/#/g,"nr")] = entry[element];
      })
      
      data.push(row);
    });
	}


	function MyComponent() {
		return (
			<div className="dataTable">
				<button onClick={(e)=>handleRefreshBtn(e)} style={{display:btnDisplay, marginLeft:"15px"}}>Refresh table</button>
				<DataTable
					columns={finalData}
          			onSort={handleSort}
					data={data}
					responsive
					expandableRows 
					expandableRowsComponent={ExpandedComponent}
					defaultSortFieldId={window.sortedcolumn}
					defaultSortAsc={window.sortDirection}
					onRowExpandToggled = {handleRowExpand}
					expandableRowExpanded = {handleExpansion}
				/>
			</div>
		);
	};
	
	return (
		<div>
			<div className="flex-container">
					<div className="logoIcon">
						<img src={logoLeft} alt="Green Prix" width="150px"/>
					</div>
					<div className="tvaIcon">
						<img src={tvaLogo} alt="Green Prix" width="150px"/>
					</div>
					<div className="vwlogoIcon">
						<img src={vwLogo} alt="Green Prix" width="200px"/>
					</div>
					<div className="logoIcon">
						<img src={sponsorLogo} alt="Green Prix" width="150px" className="sponsorLogo"/>
					</div>
					<div className="tvaIcon">
						<img src={logoRight} alt="Green Prix" width="150px" className="rightLogo"/>
					</div>
				</div>
			{ headerLoading ? (
				<img src={loadingImage} alt="Loading..." />
			):(
			<div>
				<div className="flex-container mobile-container" style={{margin:"15px"}}>
					<div>
						<label for="raceDate" id="raceDate">Race Date: </label>
						<select name="raceDate" id="raceDate" onChange={(event)=>handleRaceDate(event)}>
							<option>Select race date</option>
							{raceDate.map((value)=>{
								return(
									<option key={value.id} value={value.RaceDate} name={value.RaceDate}>{moment(value.RaceDate).format('MMMM Do YYYY')}</option>
								)
							})}
						</select>
					</div>			
					<div>
						<input type="checkbox" name="checkbox" defaultChecked={checked} onChange={(event)=>handleRefresh(event)}/>Auto-refresh
					</div>			
					<div>
						<label for="raceType">Race: </label>
						<select name="raceType" id="raceType" onChange={(event)=>handleRaceType(event)}>
							<option>Select race type</option>
							{raceTypes.map((item)=>{
								return(
									<option  key={item.RaceType} value={item.RaceType} name={item.RaceType}>{item.RaceType}</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>)}

			<div>
			{ dataLoading ? (
        <>
          <img src={loadingImage} alt="Loading..." />
        </>
			):(
				MyComponent()	
			)}
			</div>
		</div>
	)
}